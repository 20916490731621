var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.transferCompanies },
      }),
      _c("ASuccessWithoutReload", {
        attrs: { api: this.api },
        on: {
          close: function () {
            this$1.api.isSuccesful = false
          },
        },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      !_vm.api.isLoading && _vm.data != null
        ? _c("v-data-table", {
            staticClass: "elevation-1 mt-4",
            attrs: {
              items: _vm.data,
              headers: _vm.dataHeader,
              search: _vm.search,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.Index",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(" " + _vm._s(_vm.data.indexOf(item) + 1) + " "),
                    ]
                  },
                },
                {
                  key: "top",
                  fn: function () {
                    return [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "py-3",
                          attrs: { width: "auto", height: "auto", flat: "" },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mt-3 mx-3" },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mb-3" },
                                    [
                                      _c(
                                        "v-toolbar-title",
                                        {
                                          staticClass:
                                            "font-weight-bold text-h4 my-3",
                                        },
                                        [_vm._v(" AAE ")]
                                      ),
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.fetch()
                                            },
                                          },
                                        },
                                        [_vm._v(" Refresh ")]
                                      ),
                                      _c(
                                        "JsonCSV",
                                        {
                                          staticClass: "mx-2",
                                          attrs: { data: _vm.data },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { color: "primary" } },
                                            [_vm._v(" Download ")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            loading: _vm.api.isLoading,
                                          },
                                          on: { click: _vm.validateInput },
                                        },
                                        [_vm._v(" Transfer ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          label: "Search",
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function ($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "item.aae_payment_date",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.aae_payment_date != null
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.convertTimeZone(item.aae_payment_date)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "item.aae_sub_start",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.aae_sub_start != null
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.convertTimeZone(item.aae_sub_start)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "item.aae_sub_end",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.aae_sub_end != null
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.convertTimeZone(item.aae_sub_end)) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "item.year_2_aae_payment_date",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.year_2_aae_payment_date != null
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.convertTimeZone(
                                    item.year_2_aae_payment_date
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "item.year_2_aae_sub_start",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.year_2_aae_sub_start != null
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.convertTimeZone(item.year_2_aae_sub_start)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "item.year_2_aae_sub_end",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.year_2_aae_sub_end != null
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.convertTimeZone(item.year_2_aae_sub_end)
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "item.aae_invoice_sent_date",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.aae_invoice_sent_date != null
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.convertTimeZone(
                                    item.aae_invoice_sent_date
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
              ],
              null,
              false,
              3392895831
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }